@use "sass:meta" as ---vn4nr7bgydh;/*
 * Copyright Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@use "sass:color";
@import "../../global/common";
@import "index";

.btn-secondary {
  @include button($background-color: $white);

  // stylelint-disable no-duplicate-selectors
  & {
    color: $btn-primary;
    border: 1px solid $btn-primary;
  }
  // stylelint-enable

  &:hover {
    color: $white;
    background-color: color.adjust($btn-primary, $lightness: -10%);
    border-color: color.adjust($btn-primary, $lightness: -10%);
  }
}

.btn-cancel {
  border: 1px solid $btn-reset;

  &:hover {
    background-color: $btn-reset;
    color: $white;
  }
}

;@include ---vn4nr7bgydh.load-css("sass-embedded-legacy-load-done:5157");